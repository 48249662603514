<template>
  <div>
    <div class="courses-filters my-2">
      <div>
        <h5>Level</h5>
        <b-form-select @change="onSelect" v-model="selectedLevel">
          <b-form-select-option key="no_level" :value="null">
            All Levels
          </b-form-select-option>
          <b-form-select-option
            v-for="(course_level, index) in $store.getters[
              'home/getCourseLevels'
            ]"
            :key="index + '_level'"
            :value="course_level.course_level_id"
          >
            {{ course_level.course_level_name }}
          </b-form-select-option>
        </b-form-select>
      </div>
      <div>
        <h5>Category:</h5>
        <b-form-select @change="onSelect" v-model="selectedCategory">
          <b-form-select-option key="no_category" :value="null">
            All Categories
          </b-form-select-option>
          <b-form-select-option
            v-for="(course_category, index) in $store.getters[
              'home/getCourseCategories'
            ]"
            :key="index + '_category'"
            :value="course_category.course_category_id"
          >
            {{ course_category.course_category_name }}
          </b-form-select-option>
        </b-form-select>
      </div>
    </div>
    <div class="course-collapse">
      <app-collapse>
        <div v-for="(course, index) in courseList" :key="index + '_course'">
          <app-collapse-item title="Title" :ref="'course_' + course.course_id">
            <template #header>
              <Course
                :id="'course_' + course.course_id"
                :course="course"
                @reload-list="
                  (e) => {
                    $emit('reload-details');
                  }
                "
                @apply-course="
                  (e) => {
                    $emit('apply-course', e);
                  }
                "
                @apply-course-for-student="
                  (e) => {
                    $emit('apply-course-for-student', e);
                  }
                "
                @recommend-course="
                  (e) => {
                    $emit('recommend-course', e);
                  }
                "
                :courseCollapse="true"
              />
            </template>
            <Course
              :course="course"
              @apply-course-for-student="
                (e) => {
                  $emit('apply-course-for-student', e);
                }
              "
              @recommend-course="
                (e) => {
                  $emit('recommend-course', e);
                }
              "
              :courseDetail="true"
            />
          </app-collapse-item>
        </div>
      </app-collapse>
    </div>
  </div>
</template>
<script>
import Course from "./Course";
import {
  BButton,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BImg,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Multiselect from "vue-multiselect";
import Ripple from "vue-ripple-directive";

export default {
  name: "UniversityCourseDetail",
  components: {
    Course,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
    BFormSelect,
    BFormSelectOption,
    Multiselect,
  },
  directives: {
    Ripple,
  },
  props: {
    courses: {
      type: Array,
      default: [],
    },
    universityDetail: {
      type: Boolean,
      default: true,
    },
    collapseCourse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      courseList: this.$props.courses,
      selectedCategory: null,
      selectedLevel: null,
      // course_id: null,
    };
  },
  computed: {},
  watch: {
    courses() {
      this.onSelect();
    },
  },
  methods: {
    onSelect() {
      const courses = this.$props.courses;
      this.courseList = courses.filter((course) => {
        let levelFlag = true;
        let categoryFlag = true;
        if (this.selectedCategory) {
          if (course.course_category_id === this.selectedCategory) {
            levelFlag = true;
          } else {
            levelFlag = false;
          }
        }
        if (this.selectedLevel) {
          if (course.course_level_id === this.selectedLevel) {
            categoryFlag = true;
          } else {
            categoryFlag = false;
          }
        }
        return levelFlag && categoryFlag;
      });
    },
    setSavedFilters() {
      const filtersStr = localStorage.getItem("search_filters");
      if (filtersStr) {
        const filters = JSON.parse(filtersStr);
        this.selectedCategory = filters.course_category_ids[0]
          ? filters.course_category_ids[0]
          : null;
        this.selectedLevel = filters.course_level_ids[0]
          ? filters.course_level_ids[0]
          : null;
        if (this.selectedCategory && this.selectedLevel) {
          this.onSelect();
        }
      }
    },
  },
  mounted() {
    const course_id = this.$route.query.course_id;

    if (course_id) {
      this.$nextTick(() => {
        setTimeout(() => {
          const id_str = "course_" + course_id;
          const el = document.getElementById(id_str);
          el.scrollIntoView();
          el.click();
          window.scrollBy(0, -100);
        }, 1000);
      });
    }
  },
  beforeMount() {
    this.setSavedFilters();
    this.$store.dispatch("home/onGetCourseLevels");
    this.$store.dispatch("home/onGetCourseCategories");
  },
};
</script>
<style>
</style>